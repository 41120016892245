import React, { Component } from 'react';
import {
  Form,FormControl, Button, Row, Col
} from 'react-bootstrap';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { subscribeEmail,loadSubscriptions } from "../actions/subscription";
import { withTranslation, Trans } from 'react-i18next';
import { BellFill } from 'react-bootstrap-icons';


class Subscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      subscribed: false,
      subscribeInProgress: false,
      error: ""
    }
    this.handleSubscribeSubmit = this.handleSubscribeSubmit.bind(this);
    this.handleSubscribeInput = this.handleSubscribeInput.bind(this);
  }

  handleSubscribeInput = event => {
    this.setState({
      email: event.target.value
    });
  };

  handleSubscribeSubmit = e  => {
  e.preventDefault();
    if (this.state.email) {
        this.props.subscribeEmail({email: this.state.email,wishlist:this.props.wishlistId})
        //this.props.loadSubscriptions(this.state.email)
    }
  };

  render() {
    const { wishlistId,subscriptions } = this.props
    const isSubscribed = subscriptions.subscriptions.indexOf(wishlistId) === -1
      return (
        <>
          {isSubscribed ?  (
            <>
             <Row className="float-right mt-2">
                <Form inline  onSubmit={this.handleSubscribeSubmit}>
                  <Col xs={7}>
                    <FormControl onChange={this.handleSubscribeInput} value={this.state.email} type="email" placeholder="Email" className="mr-sm-2" autoFocus />
                  </Col>
                  <Col xs={5}>
                    <Button type="submit" variant="success"><Trans>Watch</Trans></Button>
                  </Col>
                </Form>
                {this.renderEmailValidationError}
              </Row>
            </>
            ) : (
              <>
                <Row className="float-right mt-2">
                  <span className="btn btn-success btn-sm"><Trans>Subscribed</Trans> <BellFill className="pl-1"/></span>
                </Row>
              </>
            )}
        </>
    )
  }
}

Subscribe.propTypes = {
  subscribeEmail: PropTypes.func.isRequired,
  loadSubscriptions: PropTypes.func.isRequired,
  subscriptions: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  subscriptions: state.subscriptions,
});
export default withTranslation('translations')(connect(mapStateToProps,{subscribeEmail,loadSubscriptions})(Subscribe))
