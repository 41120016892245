import axios from 'axios';

import {
  GET_ERRORS, ADD_TO_SUBSCRIPTIONS, SUBSCRIPTION_LOADING, LOAD_SUBSCRIPTIONS,
} from './types';
 import config from '../config/config';
// Register User

// Add to subscriptions
export const addtoSubscriptions = (wishlist) => ({
  type: ADD_TO_SUBSCRIPTIONS,
  wishlist,
});

// load initial subscriptions for email
export const loadSubscriptions = (email) => (dispatch) => {
  axios
    .get(`${global.gConfig.backendPath}subscriptions/${email}`)
    .then((res) => {
      // console.log(typeof (res));
      if (typeof res.data !== 'undefined') {
        const subscriptions = res.data;
        dispatch({
          type: LOAD_SUBSCRIPTIONS,
          subscriptions,
        });
      }
    })
    .catch((err) => {
      // console.log(err)
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

export const subscribeEmail = (subscriptionData) => (dispatch) => {
  axios
    .post(`${global.gConfig.backendPath}wishlist/${subscriptionData.wishlist}/subscribe`, subscriptionData)
    .then(() => {
      dispatch(addtoSubscriptions(subscriptionData.wishlist));
    })
    .catch((err) => {
      // console.log(err)
      dispatch(loadSubscriptions(subscriptionData.email));
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

// User loading
export const setSubscriptionLoading = () => ({
  type: SUBSCRIPTION_LOADING,
});
