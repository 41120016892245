import React, { Component } from 'react';
import axios from 'axios';
import { Image, Transformation } from 'cloudinary-react';
import { withTranslation, Trans } from 'react-i18next';
import {
  Spinner,
} from 'react-bootstrap';
import history from '../history';

class CreateWish extends Component {
  constructor(props) {
    super(props);

    this.onChangeWishDescription = this.onChangeWishDescription.bind(this);
    this.onChangeWishLink = this.onChangeWishLink.bind(this);
    this.onChangeWishStore = this.onChangeWishStore.bind(this);
    this.onChangeWishPrice = this.onChangeWishPrice.bind(this);
    this.onChangeWishImgLink = this.onChangeWishImgLink.bind(this);
    this.onChangeWishUpdates = this.onChangeWishUpdates.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.fileInput = React.createRef();
    this.onChangeFileUpload = this.onChangeFileUpload.bind(this);

    this.state = {
      wish_description: '',
      wish_link: '',
      wish_imgLink: '',
      wish_store: '',
      wish_price: '',
      wish_completed: false,
      uploading: false,
      wish_updates: false,
    };
  }

  onChangeFileUpload(e) {
    const files = Array.from(e.target.files);
    this.setState({ uploading: true });
    const formData = new FormData();

    formData.append('image', files[0]);

    axios.post(`${global.gConfig.backendPath}image-upload`, formData)
      .then((res) => {
        if (res.data.status === true) {
          this.setState({
            wish_imgLink: res.data.imageUrl,
            uploading: false,
          });
        }
      });
  }

  onChangeWishDescription(e) {
    const q = e.target.value;
    this.setState({
      wish_description: q,
    });
  }

  onChangeWishUpdates(e) {
    this.setState({
      wish_updates: e.target.value,
    });
  }

  onChangeWishLink(e) {
    this.setState({
      wish_link: e.target.value,
    });
  }

  onChangeWishImgLink(e) {
    this.setState({
      wish_imgLink: e.target.value,
    });
  }

  onChangeWishStore(e) {
    this.setState({
      wish_store: e.target.value,
    });
  }

  onChangeWishPrice(e) {
    this.setState({
      wish_price: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    const newWish = {
      wish_description: this.state.wish_description,
      wish_link: this.state.wish_link,
      wish_store: this.state.wish_store,
      wish_imgLink: this.state.wish_imgLink,
      wish_price: this.state.wish_price,
      wish_completed: this.state.wish_completed,
      wish_updates: this.state.wish_updates,
    };

    axios.post(`${global.gConfig.backendPath}wishlist/${this.props.match.params.id}/wishes/add`, newWish)
      .then((res) => {
        if (res.data.status === true) {
          history.push(`/wishlist/${this.props.match.params.id}/wishes/`);
        } else {
          alert(res.data.message);
        }
      });

    this.setState({
      wish_description: '',
      wish_link: '',
      wish_store: '',
      wish_price: '',
      wish_imgLink: '',
      wish_completed: false,
      wish_updates: false,
    });
  }

  render() {
    const { t } = this.props;
    return (
      <div style={{ marginTop: 20 }}>
        <h3><Trans>Create New Wish</Trans></h3>
        <form onSubmit={this.onSubmit}>
          <div className="form-group">
            <label>
              <Trans>Description</Trans>
              :
              {' '}
            </label>
            <input
              type="text"
              className="form-control"
              value={this.state.wish_description}
              onChange={this.onChangeWishDescription}
              required="required"
            />
          </div>
          <div className="form-group">
            <label>
              <Trans>Link</Trans>
              :
              {' '}
            </label>
            <input
              type="text"
              className="form-control"
              value={this.state.wish_link}
              onChange={this.onChangeWishLink}
            />
          </div>
          <div className="row">

            <div className="col">
              {(!this.state.wish_imgLink)
                ? (
                  <div className="form-group">
                    <label><Trans>Image</Trans></label>
                    <input className="form-control-file" type="file" id="single" onChange={this.onChangeFileUpload} ref={this.fileInput} />
                  </div>
                )
                : ''}
            </div>

            <div className="col">
              {(!this.state.wish_imgLink && this.state.uploading) ? (
                <Spinner animation="border" role="status">
                  <span className="sr-only">Uploading...</span>
                </Spinner>
              ) : ''}
              {(this.state.wish_imgLink && !this.state.wish_imgLink.indexOf('http') > -1)
                ? (
                  <Image width="150" cloudName="dff6esjqu" publicId={this.state.wish_imgLink}>
                    <Transformation width="200" quality="auto:eco" fetchFormat="auto" />
                  </Image>
                ) : <img src={this.state.wish_imgLink} width="150" />}
            </div>
          </div>
          <div className="form-group">
            <label>
              <Trans>Store</Trans>
              :
              {' '}
            </label>
            <input
              type="text"
              className="form-control"
              value={this.state.wish_store}
              onChange={this.onChangeWishStore}
              required="required"
            />
          </div>
          <div className="form-group">
            <label>
              <Trans>Price</Trans>
              :
              {' '}
            </label>
            <input
              type="text"
              className="form-control"
              value={this.state.wish_price}
              min="0"
              step="0.01"
              placeholder="0.00"
              onChange={this.onChangeWishPrice}
              required="required"
            />
          </div>
          <div className="form-group">
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                onChange={this.onChangeWishUpdates}
                className="custom-control-input"
                id="custom-switch"
                checked={this.state.wish_updates}
              />
              <label htmlFor="custom-switch" className="custom-control-label"><Trans>Send update emails for this wish</Trans></label>
            </div>
          </div>
          <div className="form-group">
            <input type="submit" value={t('Create Wish')} className="btn btn-primary" />
          </div>
        </form>
      </div>
    );
  }
}

export default withTranslation('translations')(CreateWish);
