import React, { Component } from 'react';
import { connect } from 'react-redux';

import axios from 'axios';
import {
  Alert, Container,
} from 'react-bootstrap';
import { withTranslation, Trans } from 'react-i18next';
import history from '../../history';

class Verify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verified: false,
      verification_started: false,
      verification_error: '',
    };
    this.verifyUser = this.verifyUser.bind(this);
    this.resendToken = this.resendToken.bind(this);
  }

  verifyUser() {
    this.setState({
      verification_started: true,
    });
    axios.get(`${global.gConfig.backendPath}users/verify/${this.props.match.params.token}`)
      .then((response) => {
        if (response.status === true) {
          this.setState({
            verified: true,
          });
          history.push('/login');
        } else {
          this.setState({
            verification_error: response.message,
          });
        }
      })
      .catch((error) => {
        this.setState({
          verification_error: error.message,
        });
      });
  }

  resendToken(e) {
    const formData = new FormData();
    formData.append('email', e);

    axios.post(`${global.gConfig.backendPath}users/resend-token`, formData)
      .then((res) => {
        if (res.data.status === true) {
          this.setState({
            verification_error: res.data.message,
          });
        }
      })
      .catch((error) => {
        this.setState({
          verification_error: error.message,
        });
      });
  }

  componentDidMount() {
    this.verifyUser();
  }

  render() {
    return (
      <Container>
        <h4>
          <b>Verify your email</b>
        </h4>
        {
        (this.state.verified === false && this.state.verification_started === true)
          ? (
            <Alert variant="info">
              {this.state.verification_error}
            </Alert>
          )
          : ''
}
        {(this.props.auth.isAuthenticated === true && this.props.auth.user.verified === false) ? <button className="btn btn-success btn" onClick={() => this.resendToken(this.props.auth.user.email)}><Trans>Resend verification email</Trans></button> : ''}
      </Container>

    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default withTranslation('translations')(connect(mapStateToProps)(Verify));
