import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: {
        Wishlists: 'Wishlists',
        'All lists': 'All lists',
        'My lists': 'My lists',
        Login: 'Login',
        Logout: 'Logout',
        Register: 'Register',
        'Forgot password?': 'Forgot Password?',
        'Search wishlist': 'Search wishlist',
        Search: 'Search',
        Name: 'Name',
        Description: 'Description',
        Actions: 'Action',
        'Create Wishlist': 'Create Wishlist',
        'Sort wishes on': 'Sort wishes on',
        'Price low-high': 'Price low-high',
        'Price high-low': 'Price high-low',
        Edit: 'Edit',
        Delete: 'Delete',
        View: 'View',
        Buy: 'Give this wish',
        'Dont buy': 'Sorry, too late',
        'Add new wish': 'Add new wish',
        '<p>Nobody likes to give something, someone else already gave before you, therefore we would like you to enter the provided password in combination with your email address before actually marking this gift as given. The email is being used for validation when releasing the gift again for someone else.</p>': 'Nobody likes to give something, someone else already gave before you, therefore we would like you to enter the provided password in combination with your email address before actually marking this gift as given. The email is being used for validation when releasing the gift again for someone else.',
        'Validate password': 'Confirm data and continue',
        'Password hint': 'Password hint',
        'Enter your wish to see some suggestions from Bol.com': 'Enter your wish to see some suggestions from Bol.com',
        Description: 'Description',
        Link: 'Link',
        'Image url': 'Image url',
        Store: 'Store',
        Price: 'Price',
        'Create Wish': 'Create Wish',
        Image: 'Image',
        'Loading wishlists...': 'Loading wishlists...',
        'Loading wishes...': 'Loading wishes...',

      },
    },
    nl: {
      translations: {
        Wishlists: 'Overzicht met alle lijstjes',
        'All lists': 'Alle Lijstjes',
        'My lists': 'Mijn lijstjes',
        Login: 'Inloggen',
        Logout: 'Uitloggen',
        'Forgot password?': 'Wachtwoord vergeten?',
        Register: 'Aanmelden',
        'Search wishlist': 'Zoek een lijstje',
        Search: 'Zoeken',
        Name: 'Naam',
        Description: 'Omschrijving',
        Actions: 'Acties',
        'Create Wishlist': 'Lijstje toevoegen',
        'Sort wishes on': 'Wensen sorteren op',
        'Price low-high': 'Prijs laag-hoog',
        'Price high-low': 'Prijs hoog-laag',
        Edit: 'Wijzigen',
        View: 'Bekijken',
        Delete: 'Archiveren',
        Buy: 'Deze geef ik',
        'Dont buy': 'Iemand is je voor ',
        'Add new wish': 'Extra wensje',
        '<p>Nobody likes to give something, someone else already gave before you, therefore we would like you to enter the provided password in combination with your email address before actually marking this gift as given. The email is being used for validation when releasing the gift again for someone else.</p>': 'Niemand vindt het leuk om iets te dubbel te geven, daarom verzoeken we je om hieronder je e-mail op te geven, zodat iemand anders niet per ongeluk op de knop "Koop toch niet" kan klikken. <p>Als je het toch niet wilt geven dan zit je hier ook goed. Vul dan het wachtwoord in en je e-mail en het wensje wordt weer vrijgegeven voor iemand anders.</p> ',
        'Validate password': 'Gegevens bevestigen en doorgaan',
        'Password hint': 'Hint voor het wachtwoord',
        'Enter your wish to see some suggestions from Bol.com': 'Vul je wens in om suggesties te zien van Bol.com',
        Description: 'Omschrijving',
        Link: 'Url naar product',
        'Image url': 'Url naar afbeelding van product',
        Store: 'Winkel',
        Price: 'Prijs',
        Image: 'Afbeelding',
        'Loading wishlists...': 'Op zoek naar leuke lijstjes, even geduld!',
        'Loading wishes...': 'Wensen aan het bedenken, even geduld!',
        Watch: 'ABONNEREN',
        Subscribed: 'GEABONNEERD',
        'Send update emails for this wish': 'Verstuur een update email naar alle geabonneerden en creëer een hype',
        'Edit this list': 'Instellingen',
        'Create New Wish': 'Extra wens toevoegen',
        'Create Wish': 'En toevoegen maar',
      },
    },
  },
  fallbackLng: 'en',
  debug: false,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },

  react: {
    useSuspense: true,
  },
});

export default i18n;
