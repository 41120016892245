import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import classnames from "classnames";
import { Lock, Person } from 'react-bootstrap-icons';
import { withTranslation, Trans } from 'react-i18next';
class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {}
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated && this.props.auth.user.verified === true) {
      this.props.history.push("/");
    }
    if (this.props.auth.isAuthenticated && this.props.auth.user.verified === false) {
      this.props.history.push("/user/verify/");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.auth.isAuthenticated && this.props.auth.user.verified === true) {
      this.props.history.push("/");
    }
    if (this.props.auth.isAuthenticated && this.props.auth.user.verified === false) {
      this.props.history.push("/user/verify");
    }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password
    };

    this.props.loginUser(userData);
    console.log(this.errors)
  };

  render() {
    const errors = (this.state.errors.message) ? this.state.errors.message : this.state.errors
  const { t } = this.props
    return (
      <div className="container mt-4">
          <div className="card d-block">
<div className="card-body">
  <Link to="/register"  className="float-right btn btn-outline-primary"><Trans>Register</Trans></Link>
  <h4 className="card-title mb-4 mt-1"><Trans>Login</Trans></h4>
  <hr />
  <form onSubmit={this.onSubmit}>
  <div className="form-group">
  <div className="input-group">
    <div className="input-group-prepend">
        <span className="input-group-text"> <Person /> </span>
     </div>
                    <input
              placeholder={t('Email')}
                  onChange={this.onChange}
                  value={this.state.email}
                  error={errors.email}
                  id="email"
                  type="email"
                  className={classnames("form-control", {
                    "is-invalid": errors.email || errors.emailnotfound
                  })}
                />
  </div>
     <span className="text-danger">{errors.email}</span>
  </div>
  <div className="form-group">
  <div className="input-group">
    <div className="input-group-prepend">
        <span className="input-group-text"> <Lock /> </span>
     </div>
                      <input
                placeholder="******"
                  onChange={this.onChange}
                  value={this.state.password}
                  error={errors.password}
                  id="password"
                  type="password"
                  className={classnames("form-control", {
                    "is-invalid": errors.password || errors.passwordincorrect
                  })}
                />
  </div>
     <span className="text-danger">{errors.password}</span>
  </div> 
  <div className="form-group">
  <button type="submit" className="btn btn-primary btn-block"> Login  </button>
  </div> 
  <p className="text-center"><Link to="/user/password-recover" className="btn"><Trans>Forgot password?</Trans></Link></p>
  </form>
</div>
</div>
      </div>
    );
  }
}
Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default withTranslation('translations')(connect(
  mapStateToProps,
  { loginUser }
)(Login));