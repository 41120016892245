import { Component } from 'react';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import history from '../../history';

class Logout extends Component {
  componentWillMount() {
    this.props.dispatch(logoutUser());
    history.push('/login');
  }

  render() {
    return null;
  }
}
export default connect()(Logout);
