import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation, Trans } from 'react-i18next';
import {Image, Transformation} from 'cloudinary-react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import {
  Modal, Button, Alert, Spinner, Jumbotron, Form, Row, Col,CardColumns,Card,Dropdown,ButtonGroup
} from 'react-bootstrap';
import Subscribe from './subscribe.component';
import { HeartFill,Heart,Archive,Gear,EmojiSunglasses } from 'react-bootstrap-icons';
import logo from '../logo.svg';
const Wish = (props) => (

<Card border={props.wish.wish_completed ? 'danger' : ''}>
    <Card.Body>
    <Row>
          {(props.wish.wish_imgLink && !props.wish.wish_imgLink.indexOf("http") > -1) ?
          <Image width="100" cloudName="dff6esjqu" publicId={props.wish.wish_imgLink} >
            <Transformation  width="100" quality="auto:eco" fetchFormat="auto" />
          </Image> : <img src={props.wish.wish_imgLink} width="50" />
          }
</Row>
<Row>
      
<a target="_blank" rel="noopener noreferrer" href={props.wish.wish_link}><Card.Text>
    {props.wish.wish_description} <br /><br />
       € {props.wish.wish_price} - {props.wish.wish_store}
      </Card.Text>
      </a>
    </Row>  
      
    </Card.Body>
    <Card.Footer className="text-center">
          {(props.auth.isAuthenticated === true && props.auth.user.id === props.wishlist.wishlist_owner) ? <Link className="btn btn-warning mb-1" to={`/wishlist/${props.wishlistId}/wishes/edit/${props.wish._id}`}><Trans>Edit</Trans><Gear className="pl-1" /></Link> : ''}{' '}
      <button className={props.wish.wish_completed ? 'btn btn-danger ml-1 mb-1' : 'btn btn-success ml-1 mb-1'} onClick={() => props.onItemClick(props.wish._id, !props.wish.wish_completed)}>{props.wish.wish_completed ? <><Trans>Dont buy</Trans>><EmojiSunglasses className="pl-1" /></> : <><Trans>Buy</Trans><Heart className="pl-1" /></>}</button>
      {(props.auth.isAuthenticated === true && props.auth.user.id === props.wishlist.wishlist_owner) ? <><button className="btn btn-danger ml-1 mb-1" onClick={() => props.deleteWish(props.wish)}><Trans>Delete</Trans><Archive className="pl-1" /></button></>  : ''}
      </Card.Footer>
  </Card>
);

withTranslation(['translations'])(Wish);

const Wishes = (props) => (
  props.wishes.map((wish) => (
    <Wish
      auth={props.auth}
      key={wish._id}
      wish={wish}
      onItemClick={props.onItemClick}
      wishlistId={props.wishlistId}
      deleteWish={props.deleteWish}
      wishlist={props.wishlist}
    />
  ))
);

  const LoadingSpinner = () => {
  return (
    <>
      <Spinner animation="grow" variant="primary" />
      <Spinner animation="grow" variant="secondary" />
      <Spinner animation="grow" variant="success" />
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="warning" />
      <Spinner animation="grow" variant="info" />
      <Spinner animation="grow" variant="light" />
      <Spinner animation="grow" variant="dark" />
      <span><Trans>Loading wishes...</Trans></span>
    </>
  );
}

class WishesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wishes: [],
      wishes_sorting: 'price-up',
      wishes_filter_active: 'true',
      showModal: false,
      wishlist_password: '',
      buyer_email: '',
      buy_error: '',
      buy_error_showMessage: false,
      wishlist: [{wishlist_background_color: 'default'}],
      wishlist_password_validation_inProgress: false,
      wishlist_password_validated: false,
      wishlist_password_showMessage: false,
      isLoading: true
    };
    this.updateWishes = this.updateWishes.bind(this);
    this.buyWish = this.buyWish.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.showPasswordModal = this.showPasswordModal.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeBuyerEmail = this.onChangeBuyerEmail.bind(this);
    this.populateWishlist = this.populateWishlist.bind(this);
    this.onChangeSorting = this.onChangeSorting.bind(this);
    this.deleteWish = this.deleteWish.bind(this);
  }

  close() {
    this.setState({ showModal: false });
  }

  open(action, wish, state) {
    this.setState({
      showModal: true, showModalAction: action, showModalWish: wish, showModalWishState: state,
    });
  }

  showPasswordModal(action, wish, state) {
    if (!this.state.wishlist_password_validated) this.open(action, wish, state);
  }

  onChangePassword(e) {
    this.setState({
      wishlist_password: e.target.value,
    });
  }

  onChangeBuyerEmail(e) {
    this.setState({
      buyer_email: e.target.value,
    });
  }

  onChangeSorting(e) {
    this.props.history.push(`/wishlist/${this.props.match.params.id}/wishes/?sort=${e.target.value}`);
    this.setState({ wishes_sorting: e.target.value });
  }

  validatePassword() {
    this.setState({ wishlist_password_validation_inProgress: true });
    const obj = {
      wishlist_password: this.state.wishlist_password,
    };
    axios.post(`${global.gConfig.backendPath}wishlist/${this.props.match.params.id}/validate-password`, obj)
      .then((response) => {
        if (response.data.password_match === true) {
          this.setState({ wishlist_password_validated: true });
          if (this.state.showModalAction === 'buy') {
            this.buyWish(this.state.showModalWish, this.state.showModalWishState, this.state.buyer_email);
          } else {
          this.close()
          }
        } else {
          this.setState({ wishlist_password_validated: false });
          this.setState({ wishlist_password_showMessage: true });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this.setState({ wishlist_password_validation_inProgress: false });
  }

  populateWishlist() {
    return new Promise((resolve, reject) => {
    this.setState({
            isLoading:true,
          })
      axios.get(`${global.gConfig.backendPath}wishlist/${this.props.match.params.id}`)
        .then((response) => {
          this.setState({
            wishlist: response.data,
            isLoading:false,
          }, () => {
              resolve("done");
          })
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  updateWishes() {
    const params = new URLSearchParams(this.props.location.search);

    axios.get(`${global.gConfig.backendPath}wishlist/${this.props.match.params.id}/wishes/?${params}`)
      .then((response) => {
        this.setState({ wishes: response.data.wishes });
      })
      .catch((error) => {
        console.log(error);
      });
  }



  setBuyFieldForUser = e => {
  if(this.props.auth.isAuthenticated === true) {
      this.setState({ 
        wishlist_password_validated: true,
        buyer_email: this.props.auth.user.email 
      })
      
    }
  }

  componentDidMount(e) {
    this.populateWishlist()
    .then(result =>
      this.updateWishes()
    )
    .then(result =>
        this.setBuyFieldForUser()
     )

  }

  componentDidUpdate(e) {
    if (e && this.props.location.search && e.location.search !== this.props.location.search) {
      this.updateWishes();
    }
  }

  buyWish(wish, state) {
  console.log("start")
    if (!this.state.wishlist_password_validated || !this.state.buyer_email) {
    console.log("Modal")
    console.log(this.state.wishlist_password_validated + " : " + this.state.buyer_email)
      this.showPasswordModal('buy', wish, state);
    } else {
      console.log("No modal, continue")
      const obj = {
        wish_completed: state,
        wish_buyer_email: this.state.buyer_email
      };
      axios.post(`${global.gConfig.backendPath}wishes/${wish}/buy`, obj)
        .then((response) => {
          this.updateWishes();
          this.close();
        })
        .catch((error) => {
          this.setState({
            buy_error: error.response.data.message,
            buy_error_showMessage: true,
          })
          this.open();
        });
    }
  }

deleteWish(wish) {
    const obj = {
      wish_description:wish.wish_description,
      wish_link: wish.wish_link,
      wish_imgLink: wish.wish_imgLink,
      wish_store: wish.wish_store,
      wish_price: wish.wish_price,
      wish_completed: wish.wish_completed,
      wish_active: false,
    };

    axios.post(`${global.gConfig.backendPath}wishes/update/${wish._id}`, obj)
      .then((res) => {
        if (res.data.status === true) {
          const wishes = this.state.wishes.filter(function(item) { return item._id != wish._id });
          this.setState({wishes: wishes});
        }
      });
}


  render() {
  const { t } = this.props
    return (
<>
      <Helmet><title>{`Wensenlijstje van ${this.state.wishlist.wishlist_name} een echte Donkey`}</title></Helmet>

    <div className="wishlist">

        <Jumbotron className={(this.state.wishlist.wishlist_background_color ? this.state.wishlist.wishlist_background_color : 'default')}>
        <Row>
        <Col  xs={6}>
        {
        (this.state.wishlist.wishlist_image_link && this.state.wishlist.wishlist_image_link !== '')  ?

        
        <figure className="swing"><Image height="150" cloudName="dff6esjqu" publicId={this.state.wishlist.wishlist_image_link}>
            <Transformation  height="150" width="150" radius="max" crop="lfill"  quality="auto" fetchFormat="auto" />
          </Image></figure>
          : <div className="placeholder wishlist"><figure className="swing"><img src={logo} /></figure></div>
     
          }

            
            </Col>
            <Col  xs={6}>
            <h1>{this.state.wishlist.wishlist_name}</h1>
            <p>
              {this.state.wishlist.wishlist_description}
            </p>
            </Col>
            </Row>
              <Col>
              <Subscribe wishlistId={this.props.match.params.id} />
              </Col>
          </Jumbotron>
              
        <Form.Group as={Row} controlId="sorting-filtering.ControlSelect1">
          <Col xs={7}>
           {(this.props.auth.isAuthenticated === true && this.state.wishlist.wishlist_owner === this.props.auth.user.id) ? <>
<Dropdown as={ButtonGroup}>
              <Button as={Link} to={`/wishlist/${this.state.wishlist.wishlist_id}/wishes/add`}
 variant="success">{t('Add new wish')} <HeartFill className="pl-1"/></Button>
              <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />

              <Dropdown.Menu>
             <Dropdown.Item as={Link} to={`/wishlist/${this.state.wishlist.wishlist_id}/edit/`}variant="success">{t('Edit this list')}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
          : ''}
            



              
              
          </Col>
          <Col>
            <Form.Control as="select" value={this.state.wishes_sorting} onChange={this.onChangeSorting}>
              <option value=""> </option>
              <option value="price-up">{t('Price low-high')}</option>
              <option value="price-down">{t('Price high-low')}</option>
            </Form.Control>
            </Col>
        </Form.Group>
        <CardColumns>
        {this.state.isLoading ? <LoadingSpinner /> : <Wishes deleteWish={this.deleteWish} auth={this.props.auth} wishes={this.state.wishes} wishlistId={this.props.match.params.id} wishlist={this.state.wishlist} onItemClick={this.buyWish} /> }
            
        </CardColumns>

        <Modal show={this.state.showModal} onHide={this.close}>
          <Modal.Header closeButton>
            <Modal.Title>Fool proof wish protection</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Trans>
            <p>Nobody likes to give something, someone else already gave before you, therefore we would like you to enter the provided password in combination with your email address before actually marking this gift as given. The email is being used for validation when releasing the gift again for someone else.</p>
            </Trans>
            <hr />
            <div className="form-group">
                    <Trans>Password hint</Trans>:
              {' '}
              {this.state.wishlist.wishlist_password_hint}
            </div>
            <div className="form-group">
              <label>Password: </label>
              <input
                className="form-control"
                type="password"
                value={this.state.wishlist_password}
                onChange={this.onChangePassword}
                readOnly={this.state.wishlist_password_validated}
              />
            </div>
            <div className="form-group">
              <label>Your email: </label>
                <input
                className="form-control"
                  onChange={this.onChangeBuyerEmail}
                  value={this.state.buyer_email}
                  id="email"
                  type="email"

                />
              </div>
            <Button readOnly={this.wishlist_password_validation_inProgress} onClick={this.validatePassword}>
              {this.wishlist_password_validation_inProgress && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              )}
                    {this.state.wishlist_password_validated === true ? 'Validate buyer email' : <Trans>Validate password</Trans>}

            </Button>
          </Modal.Body>
          <Modal.Footer>
            <Alert show={this.state.wishlist_password_showMessage} variant="danger">
              Oh snap! The password is not correct. Think about the provided hint:
              {this.state.wishlist.wishlist_password_hint}
            </Alert>
            <Alert show={this.state.buy_error_showMessage} variant="danger">
              {this.state.buy_error}
            </Alert>
          </Modal.Footer>
        </Modal>
      </div>
</>
    );
  }
}
WishesList.propTypes = {
  auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth
});
export default withTranslation('translations')(connect(
  mapStateToProps
)(WishesList));


