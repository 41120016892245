import { ADD_TO_SUBSCRIPTIONS, SUBSCRIPTION_LOADING, LOAD_SUBSCRIPTIONS } from '../actions/types';

const initialState = {
  subscriptions: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_SUBSCRIPTIONS: {
      const { subscriptions } = action;
      return { ...state, subscriptions };
    }
    case ADD_TO_SUBSCRIPTIONS: {
      const newsubscription = action.wishlist;
      const subscriptions = [
        newsubscription,
        ...state.subscriptions,
      ];
      return { ...state, subscriptions };
    }
    case SUBSCRIPTION_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
