import React, { Component } from 'react';
import axios from 'axios';
import history from '../history';
import {Image, Transformation} from 'cloudinary-react';
import { withTranslation, Trans } from 'react-i18next';
import {
  Jumbotron, Spinner
} from 'react-bootstrap';

class EditWishlist extends Component {
  constructor(props) {
    super(props);

    this.onChangeWishlistDescription = this.onChangeWishlistDescription.bind(this);
    this.onChangeWishlistName = this.onChangeWishlistName.bind(this);
    this.onChangeWishlistPassword = this.onChangeWishlistPassword.bind(this);
    this.onChangeWishlistPasswordHint = this.onChangeWishlistPasswordHint.bind(this);
    this.onChangeWishlistBackgroundColor = this.onChangeWishlistBackgroundColor.bind(this);
    this.onChangeFileUpload = this.onChangeFileUpload.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.fileInput = React.createRef();

    this.state = {
      wishlist_description: '',
      wishlist_name: '',
      wishlist_password: '',
      wishlist_passwordHint: '',
      wishlist_active: true,
      wishlist_completed: false,
      wishlist_imageLink: '',
      wishlist_backgroundColor: 'default',
      uploading: false,
      images: []
    };
  }

    componentDidMount() {
    axios.get(`${global.gConfig.backendPath}wishlist/${this.props.match.params.id}`)
      .then((response) => {
      const backgroundColor = (!response.data.wishlist_background_color) ? 'default' : response.data.wishlist_background_color
      
        this.setState({
          wishlist_description: response.data.wishlist_description,
          wishlist_name: response.data.wishlist_name,
          wishlist_password: response.data.wishlist_password,
          wishlist_passwordHint: response.data.wishlist_password_hint,
          wishlist_active: response.data.wishlist_active,
          wishlist_imageLink: response.data.wishlist_image_link,
          wishlist_backgroundColor: backgroundColor
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

    onChangeFileUpload(e) {
    const files = Array.from(e.target.files)
    this.setState({ uploading: true })
    const formData = new FormData()

    formData.append("image", files[0])

    axios.post(`${global.gConfig.backendPath}image-upload`, formData)
    .then((res) => {
        if (res.data.status === true) {
          this.setState({
            wishlist_imageLink: res.data.imageUrl,
            uploading: false
          })
        }
      })
  }

  onChangeWishlistBackgroundColor(e){
    this.setState({
      wishlist_backgroundColor: e.target.value,
    });
  }

  onChangeWishlistDescription(e) {
    this.setState({
      wishlist_description: e.target.value,
    });
  }

  onChangeWishlistName(e) {
    this.setState({
      wishlist_name: e.target.value,
    });
  }

  onChangeWishlistPassword(e) {
    this.setState({
      wishlist_password: e.target.value,
    });
  }

  onChangeWishlistPasswordHint(e) {
    this.setState({
      wishlist_passwordHint: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    const newWishlist = {
      wishlist_description: this.state.wishlist_description,
      wishlist_name: this.state.wishlist_name,
      wishlist_password: this.state.wishlist_password,
      wishlist_password_hint: this.state.wishlist_passwordHint,
      wishlist_image_link: this.state.wishlist_imageLink,
      wishlist_background_color: this.state.wishlist_backgroundColor,
      wishlist_active: true,
    };

    axios.post(`${global.gConfig.backendPath}wishlist/update/${this.props.match.params.id}`, newWishlist)
      .then((res) => {
        if (res.data.status === true) {
          history.push(`/wishlist/${this.props.match.params.id}/wishes`);
        }
      });

    this.setState({
      wishlist_description: '',
      wishlist_name: '',
      wishlist_password: '',
      wishlist_passwordHint: '',
    });
  }

  render() {
    return (
      <div style={{ marginTop: 20 }}>
        <h3>Update Wishlist</h3>
        <form onSubmit={this.onSubmit}>
                  <div className="form-group">
            <label>Name: </label>
            <input
              type="text"
              className="form-control"
              value={this.state.wishlist_name}
              onChange={this.onChangeWishlistName}
            />
          </div>
          <div className="form-group">
            <label>Description: </label>
            <input
              type="text"
              className="form-control"
              value={this.state.wishlist_description}
              onChange={this.onChangeWishlistDescription}
              required="required"
            />
          </div>
          <div className="form-group">
            <label>Password: </label>
            <input
              type="text"
              className="form-control"
              value={this.state.wishlist_password}
              onChange={this.onChangeWishlistPassword}
            />
          </div>
          <div className="form-group">
          <p>This hint will be displayed when someone is watching the list. It shouldnt be equal to the password to keep it safe, but that is up to you.</p>
            <label>Password hint: </label>
            <input
              type="text"
              className="form-control"
              value={this.state.wishlist_passwordHint}
              onChange={this.onChangeWishlistPasswordHint}
            />
          </div>
          <h2>Layout</h2>
          <div className="row">

          <div className="col">
            <div className="form-group">
            <label>Image</label>
              <input className="form-control-file" type='file' id='single' onChange={this.onChangeFileUpload} ref={this.fileInput} />
              </div>
          </div>
          <div className="col">
          {(!this.state.wishlist_imageLink && this.state.uploading) ? <Spinner animation="border" role="status">
            <span className="sr-only">Uploading...</span>
          </Spinner> : ''}
          <Image width="150" cloudName="dff6esjqu" publicId={this.state.wishlist_imageLink} >
            <Transformation  width="200" quality="auto:eco" fetchFormat="auto" />
          </Image>
          </div>
          </div>
            <div className="row">
          <div className="col">          
          <div className="form-group">
            <label>Background color: </label>
            <select
              type="text"
              className="form-control"
              onChange={this.onChangeWishlistBackgroundColor}
            >
                <option selected={this.state.wishlist_backgroundColor === "default"} value="default">default</option>
                <option selected={this.state.wishlist_backgroundColor === "blue"} value="blue">blue</option>
                <option selected={this.state.wishlist_backgroundColor === "pink"} value="pink">pink</option>
                <option selected={this.state.wishlist_backgroundColor === "yellow"} value="yellow">yellow</option>
                <option selected={this.state.wishlist_backgroundColor === this.value} value="balloons">balloons</option>
                <option selected={this.state.wishlist_backgroundColor === this.value} value="seal">seals</option>
          </select>
          </div>
          </div>
          <div className="col">
          <Jumbotron className={this.state.wishlist_backgroundColor}>
          </Jumbotron>
          </div>
          </div>  
            <div className="form-group">
              <input accept="image/*" capture="user" enabled={(this.state.uploading === true) ? "disabled" : "enabled"} type="submit" value="Save Wishlist" className={(this.state.uploading === true) ? 'btn btn-success disabled' : 'btn btn-success btn-block'}/>
          </div>
        </form>
      </div>
    );
  }
}

export default withTranslation('translations')(EditWishlist)
