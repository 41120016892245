import axios from 'axios';

axios.interceptors.response.use(
  (res) => Promise.resolve(res),
  (error) => {
    let message = null;
    if (error.response) {
      if (error.response.data.message) message = error.response.data.message;
    }
    return Promise.reject({ message });
  },
);

const setAuthToken = (token) => {
  if (token) {
    // Apply authorization token to every request if logged in
    axios.defaults.headers.common.Authorization = token;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common.Authorization;
  }
};

export default setAuthToken;
