import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import classnames from "classnames";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import { Lock, Person } from 'react-bootstrap-icons';
class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      password: "",
      password2: "",
      errors: {}
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const newUser = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
      language: i18next.language
    };

    this.props.registerUser(newUser, this.props.history);
    console.log(this.state.errors.message)
  };

  render() {
    const errors = (this.state.errors.message) ? this.state.errors.message : this.state.errors
    const { t } = this.props
    return (
           <div className="container mt-4">
          <div className="card">
<article className="card-body">
  <Link to="/login"  className="float-right btn btn-outline-primary"><Trans>Login</Trans></Link>
  <h4 className="card-title mb-4 mt-1"><Trans>Register</Trans></h4>
  <hr />
  <form onSubmit={this.onSubmit}>
  <div className="form-group">
  <div className="input-group">
    <div className="input-group-prepend">
        <span className="input-group-text"> <Person /> </span>
     </div>
              <input
              type="text"
              placeholder={t('Name')}
              onChange={this.onChange}
              value={this.state.name}
              error={errors.name}
              id="name"
              className={classnames("form-control", {
                "is-invalid": errors.name
              })}
            />
    </div>
       <span className="text-danger">{errors.name}</span>
  </div>

  <div className="form-group">
    <div className="input-group">
    <div className="input-group-prepend">
        <span className="input-group-text"> <Person /> </span>
     </div>
              <input
              type="text"
              placeholder={t('Email')}
              onChange={this.onChange}
              value={this.state.email}
              error={errors.email}
              id="email"
              className={classnames("form-control", {
                "is-invalid": errors.email
              })}
            />

   </div>
   <span className="text-danger">{errors.email}</span>
  </div> 

  <div className="form-group">
    <div className="input-group">
    <div className="input-group-prepend">
        <span className="input-group-text"> <Lock /> </span>
     </div>
                      <input
                placeholder="Password"
                  onChange={this.onChange}
                  value={this.state.password}
                  error={errors.password}
                  id="password"
                  type="password"
                  className={classnames("form-control", {
                    "is-invalid": errors.password || errors.passwordincorrect
                  })}
                />

    </div>
       <span className="text-danger">{errors.password}</span>
  </div> 

  <div className="form-group">
    <div className="input-group">
    <div className="input-group-prepend">
        <span className="input-group-text"> <Lock /> </span>
     </div>
                      <input
                placeholder="Confirm password"
                  onChange={this.onChange}
                  value={this.state.password2}
                  error={errors.password2}
                  id="password2"
                  type="password"
                  className={classnames("form-control", {
                    "is-invalid": errors.password2 || errors.passwordincorrect
                  })}
                />

    </div>
       <span className="text-danger">{errors.password2}</span>
  </div> 
  <div className="form-group">
  <button type="submit" className="btn btn-primary btn-block"> <Trans>Register</Trans></button>
  </div> 
  <p className="text-center"><Link to="/user/password-recover" className="btn"><Trans>Forgot password?</Trans></Link></p>
  </form>
</article>
</div>
      </div>
    );
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});


export default withTranslation('translations')(connect(
  mapStateToProps,
  { registerUser }
)(withRouter(Register)));