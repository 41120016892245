import React, { Component } from 'react';
import { Router, Route,Switch,Redirect } from 'react-router-dom';
import history from './history';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";

import { Provider } from "react-redux";
import store from "./store";

import CreateWish from './components/create-wish.component';
import CreateWishlist from './components/create-wishlist.component';
import EditWish from './components/edit-wish.component';
import WishList from './components/wish-list.component';
import EditWishList from './components/edit-wishlist.component';
import WishlistList from './components/wishlist-list.component';
import VerifySubscription from './components/verify-subscription.component';
import Register from "./components/auth/register.component";
import Login from "./components/auth/login.component";
import Verify from "./components/auth/verify.component";
import Recover from "./components/auth/password-recover.component";
import Reset from "./components/auth/password-reset.component";
import PrivateRoute from "./components/auth/private-route.component";
import Logout from "./components/auth/logout.component";
import Header from "./components/layout/header.component";

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
// Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "./login";
  }
}

const NoMatchPage = () => {
  return (
    <h3>404 - Not found</h3>
  );
};

class App extends Component {
  render() {
    return (
      <Provider store={store}>
      <Router history={history}>
        <Header />
        <div className="container">
         <Switch>
            <Route exact path="/" component={WishlistList} />
            <Route exact path="/suus">
              <Redirect to="/wishlist/5e809b6895995f11b63acd86/wishes/" />
            </Route>
            <Route exact path="/driedubbel">
              <Redirect to="/?search=(suus|noek|femm)"></Redirect>
            </Route>
            <Route exact path="/arie">
              <Redirect to="/wishlist/5e877ed82c74596f6e82a0d1/wishes/" />
            </Route>
            <Route exact path="/noek">
              <Redirect to="/wishlist/5e809b8a95995f11b63acd87/wishes/" />
            </Route>
            <Route exact path="/femm">
              <Redirect to="/wishlist/5e809ba895995f11b63acd88/wishes/" />
            </Route>
            <Route exact path="/kinderfeestje-femm">
              <Redirect to="/wishlist/6454ea71f23d9c00086a8a1f/wishes/" />
            </Route>
            <Route exact path="/madre">
              <Redirect to="/wishlist/60b9ce781f5b1700088e56a6/wishes/" />
            </Route>
            <Route exact path="/agnes">
              <Redirect to="/wishlist/5e877f3d2c74596f6e82a0d2/wishes/" />
            </Route>
            <Route exact path="/wishlist/:id/wishes/" component={WishList} />
            <Route exact path="/wishlist/:id/subscribe/verify/:token" component={VerifySubscription} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/user/verify/:token?" component={Verify} />
            <Route exact path="/user/password-recover" component={Recover} />
            <Route exact path="/user/password-reset/:token?" component={Reset} />

            <PrivateRoute exact path="/my-lists" component={WishlistList} />
            <PrivateRoute exact path="/wishlist/add" component={CreateWishlist} />
            <PrivateRoute exact path="/wishlist/:id/edit" component={EditWishList} />
            <PrivateRoute exact path="/wishlist/:id/wishes/add" component={CreateWish} />
            <Route exact path="/wishlist/:id/wishes/edit/:wish" component={EditWish} />
            <Route component={NoMatchPage} />
          </Switch>
        </div>
      </Router>
      </Provider>
    );
  }
}

export default App;
