import React, { Component } from 'react';
import axios from 'axios';
import {
  Alert, Container,
} from 'react-bootstrap';
import { withTranslation, Trans } from 'react-i18next';
import history from '../history';

class Verify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verified: false,
      verification_started: false,
      verification_error: '',
    };
    this.verif = this.verify.bind(this);
  }

  verify() {
    this.setState({
      verification_started: true,
    });
    axios.get(`${global.gConfig.backendPath}wishlist/${this.props.match.params.id}/subscribe/verify/${this.props.match.params.token}`)
      .then((response) => {
        if (response.status === true) {
          this.setState({
            verified: true,
          });
          history.push('/');
        } else {
          console.log('test');
          this.setState({
            verification_error: response.message,
          });
        }
      })
      .catch((error) => {
        this.setState({
          verification_error: error.message,
        });
      });
  }

  componentDidMount() {
    this.verify();
  }

  render() {
    return (
      <Container>
        <h4>
          <b>Verify your email</b>
        </h4>
        {
        (this.state.verified === false && this.state.verification_started === true)
          ? (
            <Alert variant="info">
              {this.state.verification_error}
            </Alert>
          )
          : ''
}
      </Container>

    );
  }
}

export default withTranslation('translations')(Verify);
