import React, { Component } from 'react';
import axios from 'axios';
import history from '../history';
import { withTranslation, Trans } from 'react-i18next';

class EditWish extends Component {
  constructor(props) {
    super(props);

    this.onChangeWishDescription = this.onChangeWishDescription.bind(this);
    this.onChangeWishLink = this.onChangeWishLink.bind(this);
    this.onChangeWishImgLink = this.onChangeWishImgLink.bind(this);
    this.onChangeWishStore = this.onChangeWishStore.bind(this);
    this.onChangeWishPrice = this.onChangeWishPrice.bind(this);
    this.onChangeWishCompleted = this.onChangeWishCompleted.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      wish_description: '',
      wish_link: '',
      wish_imgLink: '',
      wish_store: '',
      wish_price: '',
      wish_completed: false,
      wish_active: true
    };
  }

  componentDidMount() {
    axios.get(`${global.gConfig.backendPath}wishes/${this.props.match.params.wish}`)
      .then((response) => {
        this.setState({
          wish_description: response.data.wish_description,
          wish_link: response.data.wish_link,
          wish_imgLink: response.data.wish_imgLink,
          wish_store: response.data.wish_store,
          wish_price: response.data.wish_price,
          wish_completed: response.data.wish_completed,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onChangeWishDescription(e) {
    this.setState({
      wish_description: e.target.value,
    });
  }

  onChangeWishLink(e) {
    this.setState({
      wish_link: e.target.value,
    });
  }

  onChangeWishImgLink(e) {
    this.setState({
      wish_imgLink: e.target.value,
    });
  }

  onChangeWishStore(e) {
    this.setState({
      wish_store: e.target.value,
    });
  }

  onChangeWishPrice(e) {
    this.setState({
      wish_price: e.target.value,
    });
  }

  onChangeWishCompleted(e) {
    this.setState({
      wish_completed: !this.state.wish_completed,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const obj = {
      wish_description: this.state.wish_description,
      wish_link: this.state.wish_link,
      wish_imgLink: this.state.wish_imgLink,
      wish_store: this.state.wish_store,
      wish_price: this.state.wish_price,
      wish_completed: this.state.wish_completed,
      wish_active: this.state.wish_active,
    };
    axios.post(`${global.gConfig.backendPath}wishes/update/${this.props.match.params.wish}`, obj)
      .then((res) => { if (res.data.message === 'wish updated successfully') { history.push(`/wishlist/${this.props.match.params.id}/wishes/`);} else {alert(res.data.message);} });
  }

  render() {
    return (
      <div>
        <h3>Update Wish</h3>
        <form onSubmit={this.onSubmit}>
          <div className="form-group">
            <label>Description: </label>
            <input
              type="text"
              className="form-control"
              value={this.state.wish_description}
              onChange={this.onChangeWishDescription}
            />
          </div>
          <div className="form-group">
            <label>Link: </label>
            <input
              type="text"
              className="form-control"
              value={this.state.wish_link}
              onChange={this.onChangeWishLink}
            />
          </div>
          <div className="form-group">
            <label>Image url: </label>
            <input
              type="text"
              className="form-control"
              value={this.state.wish_imgLink}
              onChange={this.onChangeWishImgLink}
            />
          </div>
          <div className="form-group">
            <label>Store: </label>
            <input
              type="text"
              className="form-control"
              value={this.state.wish_store}
              onChange={this.onChangeWishStore}
            />
          </div>
          <div className="form-group">
            <label>Price: </label>
            <input
              type="text"
              className="form-control"
              value={this.state.wish_price}
              min="0"
              step="0.01"
              placeholder="0.00"
              onChange={this.onChangeWishPrice}
            />
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="completedCheckbox"
              name="completedCheckbox"
              onChange={this.onChangeWishCompleted}
              checked={this.state.wish_completed}
              value={this.state.wish_completed}
            />
            <label className="form-check-label" htmlFor="completedCheckbox">
                                Completed
            </label>
          </div>
          <br />
          <div className="form-group">
            <input type="submit" value="Update Wish" className="btn btn-primary" />
          </div>
        </form>
      </div>
    );
  }
}

export default withTranslation('translations')(EditWish)