import React, { Component } from 'react';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import {
  Navbar, Nav
} from 'react-bootstrap';            
import Search from '../search.component';
import logo from '../../logo.svg';

class Header extends Component {
  render() {
  const { t } = this.props
  return (

      <Navbar collapseOnSelect expand="lg" className="color-nav" variant="light">
    <Navbar.Brand href="/"><img height="40" src={logo} alt="De Donkeys"/> De Donkeys</Navbar.Brand>
      <Navbar.Toggle aria-controls="main-navbar-nav" />
  <Navbar.Collapse id="main-navbar-nav">
    <Nav className="mr-auto">
      <Link to="/" className="nav-link">{t('All lists')}</Link>
      <Link to="/my-lists" className="nav-link">{t('My lists')}</Link>
      {this.props.auth.isAuthenticated === false ? <Link to="/login" className="nav-link">{t('Login')}</Link> : <Link to="/logout" className="nav-link">{t('Logout')}</Link>}
    </Nav>
    <Search />
    </Navbar.Collapse>
  </Navbar>

    );
  }
  }
Header.propTypes = {
  auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth
});
export default withTranslation('translations')(connect(
  mapStateToProps
)(Header));