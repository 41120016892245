import React, { Component } from 'react';
import {
  Form,FormControl, Button
} from 'react-bootstrap';

import history from '../history';
import { withTranslation, Trans } from 'react-i18next';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: ""
    }
  }
  handleSearchInput = event => {
    this.setState({
      searchText: event.target.value
    });
  };

  handleSearchSubmit = e  => {
  e.preventDefault();
    if (this.state.searchText) {
        history.push(`/?search=${this.state.searchText}`);
    } else {
      history.push(`/`);
    }
  };

  render() {
    const { t } = this.props
      return (
    <Form inline onSubmit={this.handleSearchSubmit}>
      <FormControl onChange={this.handleSearchInput} value={this.state.searchText} type="text" placeholder={t('Search wishlist')} className="mr-sm-2" />
      <Button type="submit" variant="outline-success">{t('Search')}</Button>
    </Form>
    )
  }

}

export default withTranslation('translations')(Search)