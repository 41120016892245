import axios from 'axios';
import jwtDecode from 'jwt-decode';
import setAuthToken from '../utils/setAuthToken';
import history from '../history';

import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING } from './types';
 import config from '../config/config';
// Register User
export const registerUser = (userData) => (dispatch) => {
  axios
    .post(`${global.gConfig.backendPath}users/register`, userData)
    .then(() => history.push('/login'))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

// Set logged in user
export const setCurrentUser = (decoded) => ({
  type: SET_CURRENT_USER,
  payload: decoded,
});

// Login - get user token
export const loginUser = (userData) => (dispatch) => {
  axios
    .post(`${global.gConfig.backendPath}users/login`, userData)
    .then((res) => {
      // Save to localStorage

      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem('jwtToken', token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwtDecode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
      history.push('/my-lists');
    })
    .catch((err) => dispatch({
      type: GET_ERRORS,
      payload: err,
    }));
};

// User loading
export const setUserLoading = () => ({
  type: USER_LOADING,
});

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem('jwtToken');
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
