import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";
import { Lock } from 'react-bootstrap-icons';
import { withTranslation, Trans } from 'react-i18next';
import axios from 'axios';
import i18next from 'i18next';
import history from '../../history';

class Reset extends Component {
  constructor() {
    super();
    this.state = {
      password: "",
      password2: "",
      token:"",
      errors: {}
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Reset page, should redirect them to wishlists overview
    if (this.props.auth.isAuthenticated && this.props.auth.user.verified === true) {
      this.props.history.push("/");
    }
    if (this.props.auth.isAuthenticated && this.props.auth.user.verified === false) {
      this.props.history.push("/user/verify/");
    }
    this.setState({token: this.props.match.params.token})
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.auth.isAuthenticated && this.props.auth.user.verified === true) {
      this.props.history.push("/");
    }
    if (this.props.auth.isAuthenticated && this.props.auth.user.verified === false) {
      this.props.history.push("/user/verify");
    }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const formData = new FormData()
    formData.append("token", this.state.token)
    formData.append("password", this.state.password)
    formData.append("password2", this.state.password2)
    formData.append("language", i18next.language)

    axios.post(`${global.gConfig.backendPath}users/password-reset`, formData)
    .then((res) => {
        if (res.data.status === true) {
          history.push(`/login`);
        }
      })
    .catch((error) => {
       this.setState({
          errors: error.error,
      });
  })

  };

  render() {
    const { errors } = this.state;
  const { t } = this.props
    return (
      <div className="container mt-4">
          <div className="card">
<article className="card-body">
  <Link to="/register"  className="float-right btn btn-outline-primary"><Trans>Register</Trans></Link>
  <h4 className="card-title mb-4 mt-1"><Trans>Reset password</Trans></h4>
  <hr />
  <form noValidate onSubmit={this.onSubmit}>
      <div className="form-group">
  <div className="input-group">
    <div className="input-group-prepend">
        <span className="input-group-text"> <Lock /> </span>
     </div>
                      <input
                  onChange={this.onChange}
                  value={this.state.token}
                  error={errors.token}
                  id="token"
                  type="token"
                  className={classnames("form-control", {
                    invalid: errors.password || errors.passwordincorrect
                  })}
                />

  </div>
  </div> 
    <div className="form-group">
  <div className="input-group">
    <div className="input-group-prepend">
        <span className="input-group-text"> <Lock /> </span>
     </div>
                      <input
                placeholder="******"
                  onChange={this.onChange}
                  value={this.state.password}
                  error={errors.password}
                  id="password"
                  type="password"
                  className={classnames("form-control", {
                    invalid: errors.password || errors.passwordincorrect
                  })}
                />

  </div>
  </div> 
    <div className="form-group">
  <div className="input-group">
    <div className="input-group-prepend">
        <span className="input-group-text"> <Lock /> </span>
     </div>
                      <input
                placeholder="******"
                  onChange={this.onChange}
                  value={this.state.password}
                  error={errors.password}
                  id="password2"
                  type="password"
                  className={classnames("form-control", {
                    invalid: errors.password || errors.passwordincorrect
                  })}
                />

  </div>
  </div> 
  <div className="form-group">
  <button type="submit" className="btn btn-primary btn-block">Reset password</button>
  </div> 
  </form>
</article>
</div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});
export default withTranslation('translations')(connect(
  mapStateToProps
)(Reset));