import React, { Component } from 'react';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
 Container, Row,Card,Spinner
} from 'react-bootstrap';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { Helmet } from 'react-helmet';
import {Image, Transformation} from 'cloudinary-react';
import { withTranslation, Trans } from 'react-i18next';
import { Shop } from 'react-bootstrap-icons';

axiosRetry(axios, { retries: 3 });

const Wishlist = (props) => (
<Card>

    <Card.Body>
    <Link to={`wishlist/${props.wishlist._id}/wishes/`}>
    <Row>
         <Image height="100" cloudName="dff6esjqu" publicId={(props.wishlist.wishlist_image_link && props.wishlist.wishlist_image_link !== '')  ?  props.wishlist.wishlist_image_link : 'jnf9apgj0q7smbukvm9l'} >
            <Transformation  height="150" width="100" radius="max" crop="lfill" quality="auto" fetchFormat="auto" />
          </Image>
          </Row>
          <Row>
      <Card.Title>{props.wishlist.wishlist_name}</Card.Title>
      </Row>
      <Row>
    <Card.Text>
   {props.wishlist.wishlist_description}
   </Card.Text>
    </Row>  
      </Link>
    </Card.Body>
    
    <Card.Footer className="text-center">
       {(props.auth.isAuthenticated === true && props.auth.user.id === props.wishlist.wishlist_owner) ? <Link className="btn btn-warning btn-sm" to={`wishlist/${props.wishlist._id}/edit/`}><Trans>Edit</Trans></Link> : ''}{' '}
      {(props.auth.isAuthenticated === true && props.auth.user.id === props.wishlist.wishlist_owner) ? <button className="btn btn-danger btn-sm" onClick={() => props.deleteWishlist(props.wishlist)}><Trans>Delete</Trans></button>  : ''}
  </Card.Footer>
    
  </Card>

);

  const LoadingSpinner = () => {
  return (
    <>
      <Spinner animation="grow" variant="primary" />
      <Spinner animation="grow" variant="secondary" />
      <Spinner animation="grow" variant="success" />
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="warning" />
      <Spinner animation="grow" variant="info" />
      <Spinner animation="grow" variant="light" />
      <Spinner animation="grow" variant="dark" />
      <span><Trans>Loading wishlists...</Trans></span>
    </>
  );
}

class WishlistList extends Component {
  constructor(props) {
    super(props);
    this.state = { wishlists: [] };
     this.updateWishlists = this.updateWishlists.bind(this);
     this.deleteWishlist = this.deleteWishlist.bind(this);
  }

updateWishlists() {
    const params = new URLSearchParams(this.props.location.search);
    const wishlistPath = (this.props.auth.isAuthenticated === true && this.props.location.pathname === '/my-lists')  ? 'wishlist/my-lists/' : 'wishlist/'
    this.setState({ loading: true }, () => {
      axios.get(`${global.gConfig.backendPath}${wishlistPath}?${params}`)
        .then((response) => {
          this.setState({ loading: false, wishlists: response.data });
        })
        .catch((error) => {
          console.log(error);
        });
      });
}

deleteWishlist(wishlist) {
    const newWishlist = {
      wishlist_active: false,
      wishlist_description: wishlist.wishlist_description,
      wishlist_name: wishlist.wishlist_name,
      wishlist_password: wishlist.wishlist_password,
      wishlist_password_hint: wishlist.wishlist_passwordHint,
      wishlist_image_link: this.state.wishlist_imageLink,
      wishlist_background_color: this.state.wishlist_backgroundColor,
    };

    axios.post(`${global.gConfig.backendPath}wishlist/update/${wishlist._id}`, newWishlist)
      .then((res) => {
        if (res.data.status === true) {
          const wishlists = this.state.wishlists.filter(function(list) { return list._id !== wishlist._id });
          this.setState({wishlists: wishlists});
          
        }
      });
}

  componentDidMount(e) {
   this.updateWishlists()
  }

    componentDidUpdate(e) {
    if (e && this.props.location.search && e.location.search !== this.props.location.search) {
      this.updateWishlists();
    }
  }

  wishLists() {
    return this.state.wishlists.map((currentWishlist, i) => <Wishlist auth={this.props.auth} deleteWishlist={this.deleteWishlist} wishlist={currentWishlist} key={i} />);
  }


  render() {
  const { t } = this.props
  const { loading } = this.state;
    return (
    <>
      <Helmet>
        <title>Overview of all available wishlists</title>
      </Helmet>
      <Row>

        {this.props.auth.isAuthenticated === true ? <Link to="/wishlist/add" className="btn btn-success btn-block">{t('Create Wishlist')}<Shop className="pl-1"/></Link> : ''}

              </Row>
              <Row className="wishlists mt-3">
              {loading ? <LoadingSpinner /> : this.wishLists() }</Row>

</>
    );
  }
}
WishlistList.propTypes = {
  auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth
});
export default withTranslation('translations')(connect(mapStateToProps)(WishlistList));
